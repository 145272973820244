import React from "react"
import ImageCarousel from "components/blocks/imageCarousel"
import "./gallery.scss"
import PropTypes from "prop-types"
const Gallery = ({ gallery }) => {
  return (
    <div className="gallery">
      {gallery && gallery.length > 0 && (
        <div className="dla-gallery-carousel">
          <ImageCarousel images={gallery} />
        </div>
      )}
    </div>
  )
}
Gallery.props = {
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
}

Gallery.propTypes = Gallery.props

export default Gallery
