import React from "react"
import PropTypes from "prop-types"
import COMPONENTS from "utils/componentConstants"
import SplitSection from "components/sections/splitSection"
import SplitCards from "components/sections/splitCards"
import SECTIONS from "utils/sectionConstants"
import Gallery from "views/gallery"
const DLATemplate = ({ gallery, sections }) => (
  <>
    {sections.map((section, i) => {
      switch (section.__typename) {
        case COMPONENTS.SplitContent:
          return <SplitSection {...section} className="inner-content" key={i} />
        case SECTIONS.TemplateDetailsSection:
          return <Gallery gallery={gallery} key={i} />
        case COMPONENTS.SplitCards:
          return <SplitCards {...section} />
        default:
          return null
      }
    })}
  </>
)

DLATemplate.props = {
  sections: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(SplitSection.props)])
  ),
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
  slug: PropTypes.string,
  title: PropTypes.string,
}

DLATemplate.propTypes = DLATemplate.props

export default DLATemplate
