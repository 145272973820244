import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import "./splitCards.scss"
import RichText from "components/elements/richText"

const SplitCards = ({ backgroundColor, cards, className, content }) => {
  return (
    <div
      className={renderClasses(`g-split-cards ${className}`, [
        [backgroundColor, `--${backgroundColor}`],
      ])}
    >
      <div className="g-split-cards__wrapper">
        <RichText {...content} className="section-text" />
        <div className="g-split-cards__grid">
          {cards.map((card, i) => (
            <div
              className={renderClasses("g-card", [
                [card.backgroundColor, `--${card.backgroundColor}`],
              ])}
              key={i}
            >
              {card.media?.image?.file?.url && (
                <img
                  alt={card.media.image.alt || ""}
                  className="g-card__image"
                  src={card.media.image.file.url}
                />
              )}
              <div className="g-card__content">
                {card.text?.json && (
                  <RichText className="card-text" richText={card.text} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

SplitCards.propTypes = {
  backgroundColor: PropTypes.oneOf([
    "blue",
    "darkGreen",
    "green",
    "yellow",
    "pink",
    "teal",
    "ivory",
    "darkBlue",
    "darkPink",
  ]),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      backgroundColor: PropTypes.string, // Use theme names
      media: PropTypes.shape({
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
          alt: PropTypes.string,
        }),
      }),
      text: PropTypes.shape({
        json: PropTypes.object, // RichText expects JSON
      }),
    })
  ),
  className: PropTypes.string,
  content: PropTypes.object,
}

export default SplitCards
